$star-default: #d2d2d2;
$star-active: #EDB867;
$star-selected: #EDB867;
$green-color: green;

.br-theme-bootstrap-stars {

    .br-widget {
        height: 28px;
        white-space: nowrap;

        a {
            font-style: normal;
            font-weight: normal;
            font-variant: normal; 
            font-size: 20px;
            font-family: 'Glyphicons Halflings';
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            margin-right: 2px;
        }

        a:after {
            content: '\e006';
            color: $star-default;
        }

        a.br-active:after {
            color: $star-active;
        }

        a.br-selected:after {
            color: $star-selected;
        }

        .br-current-rating {
            display: none;
        }
    }

    .br-readonly {
        a {
            cursor: default;
        }
    }

}

@media print {
    .br-theme-bootstrap-stars {

        .br-widget {
            a:after {
                content: '\e007';
                color: black;
            }

            a.br-active:after,
                a.br-selected:after {
                content: '\e006';
                color: black;
            }
        }

    }
}

.br-theme-fontawesome-stars-o {

    .br-widget {
        height: 28px;
        white-space: nowrap;

        a {
            ont-style: normal;
            font-weight: normal;
            font-variant: normal; 
            font-size: 20px;
            font-family: FontAwesome;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            margin-right: 2px;
        }

        a:after {
            content: '\f006';
            color: $star-default;
        }

        a.br-active:after {
            content: '\f005';
            color: $green-color;
        }

        a.br-selected:after {
            content: '\f005';
            color: $green-color;
        }

        a.br-fractional:after {
            content: '\f123';
            color: $green-color;
        }

        .br-current-rating {
            display: none;
        }
    }

    .br-readonly {
        a {
            cursor: default;
        }
    }

    .br-reverse {
        a.br-fractional {
            display: inline-block;
            transform: scaleX(-1);
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            filter: FlipH;
            -ms-filter: "FlipH";
        }    
    }

}

@media print {
    .br-theme-fontawesome-stars-o {

        .br-widget {
            a:after {
                content: '\f006';
                color: black;
            }

            a.br-active:after,
                a.br-selected:after {
                content: '\f005';
                color: black;
            }

            a.br-fractional:after {
                content: '\f123';
                color: black;
            }
        }

    }
}

.br-theme-fontawesome-stars {

    .br-widget {
        height: 28px;
        white-space: nowrap;

        a {
            font-style: normal;
            font-weight: normal;
            font-variant: normal; 
            font-size: 20px;
            font-family: FontAwesome;
            
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            text-decoration: none;
            margin-right: 2px;
        }

        a:after {
            content: '\f005';
            color: $star-default;
        }

        a.br-active:after {
            color: $star-active;
        }

        a.br-selected:after {
            color: $star-selected;
        }

        .br-current-rating {
            display: none;
        }
    }

    .br-readonly {
        a {
            cursor: default;
        }
    }

}

@media print {
    .br-theme-fontawesome-stars {

        .br-widget {
            a:after {
                content: '\f006';
                color: black;
            }

            a.br-active:after,
                a.br-selected:after {
                content: '\f005';
                color: black;
            }
        }

    }
}