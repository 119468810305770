#navmenu {
  position: fixed;
  top:0;
  z-index: 1000;
  width: 60px;
  height: 100%;
  background-color: $brand-grey;
  box-shadow: 1px 0 20px #6f6f6f;

  &:hover {
    color: $brand-primary; 
  }

  ul {
    margin: 24px 0;
    padding:0;
    list-style: none;
    margin-left: -10px;
  }

  li {
    margin: 10px auto;
    height: 48px;
    
    &:last-child{
      position: absolute;
      bottom: 20px;
    }
  
    a {
      display: block;
      position: relative;
      margin: 0;
      color: $brand-brown;    
      text-decoration: none;

      &:hover {
        text-decoration: none;
        color: #fff;
      }

      i {
        transition:all 0.3s ease-out;
        font-size: 2.5em;
        font-weight: normal;
        line-height: 48px;
        padding: 13px;
        width: 48px;
        text-align: center;
        vertical-align: middle;
        border-radius: 0;
        margin: 10px;
      }

      .title {
        font-size: $font-base;
        position: absolute;
        top: 0;
        left: 48px;
        vertical-align: middle;
        line-height: 44px;
        transition: all 0.5s ease-out;
        transform: translateX(-400px);
        opacity: 0;
        display: inline-block;
        min-width: 280px;
        border: 1px solid $brand-primary;
        color: #fff;
        background-image: linear-gradient(to left, $brand-primary-alpha, $brand-primary);
        border-radius: 5px;
        z-index: -10;
        padding-left: 80px;
      }

      &:hover { 
        
        i {
          border-radius: 0px;
        }

        .title {
          transform: translateX(-50px);
          opacity: 1;
        }
      }
    }
  }
}
