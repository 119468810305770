
.phonecall {
  font-size:0.9em;

  
  .next {
    margin-bottom:12px;
    max-width:64px;
    border-radius: 50%;
    box-shadow: 0 7px 7px grey;


  }

  .next:active {
    transform: translateY(4px);
    box-shadow: none;  
  }

  .line {
    padding:8px 16px;
    /*border:solid 1px rgba(0,0,0,0.1);*/
    transform:scale(1);
    transform-origin:50%;
    transition:transform 0.5s, background-color 0.5s, color 0.5s;
    position:relative;
    z-index: 1;
    color:lighten($brand-success,5);
    filter:blur(2px);
    opacity: 0.1; 


    &.active {
      filter:none;
      color:white;
      z-index:2;
      position:relative;
      transform:scale(1.2);
      background-color: transparent;
      opacity: 1;

    
    }
    &.inactive {
      filter:none;
      color:white;
      opacity: 1;
    }

  td {
  padding: 2px 7px;

    &.person {
      font-weight: bold;
      min-width: 80px;
      text-align: center;
    }

    &.personA {
      
      background-color: #226BAC;

    }
  
    &.personB {

      background-color: #3BA58B;

    }

    &.parolesA {
      width: 100%;
      text-align: left;
      font-style: italic;
      background-color: lighten(#226BAC,12)
    }

    &.parolesB {
      width: 100%;
      text-align: right;
      font-style: italic; 
      background-color: lighten(#3BA58B,12);

    }

    span {
      font-style: normal;
      font-weight: bold;
    }

  }

  }
  .next {
    cursor:pointer;
  }
}

