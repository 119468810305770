/* Slider */


.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    border: solid $brand-primary 2px;
    margin-top: -4px;
    margin-bottom: 7px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

}

.slick-slide {
    .paragraphe_td {
        font-size: 1.2em;
        padding-top: 10px;
        min-height: 100px;
		width: 100%;
		vertical-align: top;
		padding-left: 5px;

		.slide-paragraph-image {
			border: solid $brand-primary 1px;
			display: flex;
			justify-content: center;
		}
    }
    
    .target {
        color: $brand-white;
        vertical-align: middle;

        &.target_td {
            height: 61px;
            width: 201px;
            border: dashed $brand-primary 2px;
            background-color: $brand-white;
            border-radius: $border-radius-base;
            text-align: center;
            vertical-align: middle;
            overflow:hidden;
            display:inline-block;
            &.animated {
                animation: shake 2s;
            }
        }

        &.target-dropped {
            background-color: $brand-primary;
            padding: 20px;
        }

        &.ui-state-hover {
            background-color: $brand-primary;
        }

    }

}

.slick-track {
    position: relative;
    background-color: white;
    left: 0;
    top: 0;
    display: block;
    // border: solid black 1px;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    background: url('../img/background_slider.png') no-repeat;
    background-color: transparent;
    padding: 2px 15px;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }

    &:not(.slick-active) .animated {
    opacity: 0;
    }


    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


.btn-block {
    position: relative;
    border-radius: $border-radius-base;
    font-weight: bold;
}
    


.reponse {
    font-weight: bold;
    font-size: 1.2em;
    font-style: italic;
    color: $brand-primary;

}


.personAslide {
    background-color: darken(#226BAC, 7);
    color: white;
    font-weight:bold; 
}

.personA2slide {
    background-color: $brand-primary;
    color: white;
    font-weight:bold; 
}
  
.personBslide {
    background-color: darken(#3BA58B, 7);
    color: white;
    font-weight:bold; 
}

.parolesAslide {
    font-style: italic;
    color: darken(#226BAC, 5);
    font-weight:bold; 

}
.parolesA2slide {
    font-style: italic;
    color: $brand-primary; 
    font-weight:bold; 

}

.parolesBslide {
      font-style: italic; 
      color: darken(#3BA58B, 5);
      font-weight:bold; 

    }
