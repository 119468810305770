.btn {
    box-shadow: none;
    color: #fff;
    background-color: $brand-primary;
    padding: 5px 10px;
    border-radius: 20px;
    &.btn-primary {
        background-color: $brand-primary;
        border: none;
    }
    &:hover, &:focus, &.btn.focus {
      background-color: darken($brand-primary, 10%);
      color: #fff;
      outline: none !important;
    }
}