$brand-primary:#E2000B;
$btn-default-border: #fff;

.ratingbar {
    z-index: 1000;
    bottom: 0;
    display: inline-block;
    background-color: $brand-primary;
    border: $btn-default-border 2px solid;
    position: relative;
    margin-left: 20px;
    
    .ratingbar-icon {
        position: relative;
        z-index: 10;
        float: left;
        display: inline-block;
        height: 40px;
        width: 40px;
        img {
            height: 100%;
            width: 100%
        }
    }
    .ratingbar-container {
        position: relative;
        z-index: 1;
        display: inline-block;
        float: top;
        margin-left: -40px;
        margin-right: -40px;
        text-align: left;
        padding: 0 10px 0 10px;
        //padding-left: 44px;
        //display: inline-grid;
        
        .br-wrapper {
            padding: 7px 45px 5px;
            height: 40px;
        }
        
        .ratingbar-comment {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 10px;
            height: 150px;
            width: 100%;
            border-radius: 10px;
            resize: none;
            overflow-x: none;
            overflow-y: auto;
        }
    }
    .ratingbar-submit { 
        position: relative;
        z-index: 10;
        float: right;
        //display: inline-grid;
        width: 40px;
        height: 40px;
        &:hover{
            background-color: darken($brand-primary, 15%);
        }
    }
}