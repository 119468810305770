@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?6g7gbp');
  src:  url('../fonts/icomoon.eot?6g7gbp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?6g7gbp') format('truetype'),
    url('../fonts/icomoon.woff?6g7gbp') format('woff'),
    url('../fonts/icomoon.svg?6g7gbp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cursor:before {
  content: "\e90a";
}
.icon-cancel:before {
  content: "\e900";
}
.icon-checked:before {
  content: "\e901";
}
.icon-down-arrow:before {
  content: "\e902";
}
.icon-download:before {
  content: "\e903";
}
.icon-left-arrow:before {
  content: "\e904";
}
.icon-list:before {
  content: "\e905";
}
.icon-login:before {
  content: "\e906";
}
.icon-pie-chart:before {
  content: "\e907";
}
.icon-right-arrow:before {
  content: "\e908";
}
.icon-up-arrow:before {
  content: "\e909";
}
