.stats {
  &.offset {
    padding-left: 100px;
  }
}

.bloc {
  width:100%;
  margin-bottom:16px;
  line-height: 60px;
  height:60px;
  background-color: $brand-primary;
  border-radius: 5px;
  .v-center {
    line-height:1.4;
    vertical-align:middle;
    display:inline-block;
    margin:auto;
    width:100%;
  }
  .h-center {
    text-align:center;
  }
  &.bloc-semi {
    height:60px;
    line-height:60px;
  }
  &.bloc-primary {
    color:white;
  }
  &.bloc-success {
    /*background-color: $brand-success;*/
    color:white;
  }
  .bloc-log{
    background-image: url(../img/bg_palatine.jpg);
    background-size:cover;
  }
}

.bloc-affectation-3 {
  width:100%;
}
.bloc-affectation-4,.bloc-affectation-5,.bloc-affectation-6 {
  display:none !important;
}

ol.breadcrumb {
  display: none;
}

@media screen and (min-width: 768px) {
  .bloc {
    height:136px;
    line-height:136px;
    &.bloc-semi {
      height:60px;
      line-height:60px;
    }

  }

}
