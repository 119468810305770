
// FONT FAMILIES
$font-family-base: 'Raleway', sans-serif;
$font-family-lobster: 'Lobster 1.3', sans-serif;
$font-title: 'sf_grandezzaheavy', sans-serif;
$font-smtitle: 'sf_grandezzalight', sans-serif;

// FONT SIZES
$font-xxsmall: 0.7em;
$font-xsmall: 0.8em;
$font-small: 0.9em;
$font-base: 1em;
$font-base-1: 1.1em;
$font-base-2: 1.2em;
$font-base-3: 1.3em;
$font-medium-1: 1.4em;
$font-medium-2: 1.5em;
$font-medium-3: 1.6em;
$font-large: 1.7em;
$font-xlarge: 1.8em;
$font-xxlarge: 1.9em;
$font-double: 2em;
$font-twofive: 2.5em;
$font-triple: 3em;
$font-huge: 4em;

// COLORS
$primary: #C41200;
$secondary: #A50B01;
$tertiary : #850900;

$gray-lighter: #E9E9E9;
$gray-gray: #333333;
$gray-darker: #1E1E1E;

// OTHER BACKGROUNDS
$bg-transparent: transparent;


// NAVBAR
$navbar-default-bg:#ccc;
$navbar-default-color:#fff;
$navbar-default-link-color:#fff;
$navbar-default-link-hover-color:#E2000B;
$navbar-border-radius:0;

// BORDERS
$border-radius-none: 0px;
$border-radius-small: 3px;
$border-radius-base: 7px;
$border-radius-large: 10px;
$border-radius-xlarge: 20px;

// Black & white
$brand-white: #F9F9F9;
$brand-grey-dark: #6A6A6A;
$brand-black: #3A3A3A;

// BRAND COLORS
$brand-primary: #B1101A; 
$brand-primary-alpha: rgba(177, 16, 26, 0.5); 
$brand-secondary: #F37429; 
$brand-tertiary: #006188; 
$brand-quaternary: #EF3E2A; 
$brand-quinary: #A7629D;
$brand-brown: #887D73;
$brand-grey: #ECECEB;

$brand-default-grey: $brand-grey;
$brand-dark-green: #2A6654;
$brand-alt-green: #C4D600;
$brand-dark-red: #CB521C;

// Backgrounds
$brand-bg-blue: #36A9E1;
$brand-bg-blue-dark: #115B80;
$brand-bg-orange: #F39200;
$brand-bg-orange-dark: #CC6600;
$brand-bg-green: #8CBF3A;
$brand-bg-green-dark: #336600;
$brand-bg-pink: #E83363;
$brand-bg-pink-dark: #741A32;
$brand-bg-teal: #00A070;
$brand-bg-teal-dark: #006666;

// CAROUSEL: SLICK
$slick-dot-size: 50px;
$slick-dot-color: $brand-primary;

$text-muted: darken($gray-lighter,5);