@import "config";
@import "fonts";                      // @Fontface
@import "bootstrap";                    // CSS Framework
@import "tooltipster.bundle";           // Les tooltips
@import "tooltipster-borderless.min";   // le theme des tooltips
@import "font-awesome";                 // Icones
@import "sweetalert2";                  // Modales
@import "icomoon";                      // Icones Personnalisés
@import "animate";                      // Animation CSS
@import "videobg";                      // Background video
@import "fullpage";                     // Fullpage
@import "prettyPhoto";                  // Lightbox: popup photo
@import "chrono";                       // Chrono de la page d'accueil & de la fiche indiv
@import "quiz_miniquiz";                // quiz et miniquiz ... lol
@import "lettrine";                     // Table Grandes Lettres
@import "slick-theme";                  // Carousel "slick"
@import "slick";                        // Carousel "slick"
@import "dragndropSlide";               // Mini game carousel with slide / slick
@import "phonecall";                    // animation css interaction Dialogue
@import "learningquiz";                 // connecting popol avec des traits
@import "interactivite";                // interactivité video
@import "navmenu";                      // Navigation Bar
@import "bloc-stats";                   // bloc de couleur x divers stats
@import "chrono";                       // ???
@import "stepbystep";                   // ???
@import "crosswords";                   // mots croisés
@import "missingwords";                 // Texte a trou
@import "imageProgressive";             // Image progressive
@import "swals";                        // swals de sweet-alert

//////
@import "style";                        // Config CSS Base
@import "./settings/buttons";           // Buttons

@import "rating-star";                  // Notation
@import "ratingbar";                    // Notation
