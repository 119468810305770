.tooltipster-sidetip.tooltipster-borderless .tooltipster-box {
    border: none;
    background: $brand-primary;
    background: rgba($brand-primary,0.9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-box {
    margin-top: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-box {
    margin-right: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-box {
    margin-left: 8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-box {
    margin-bottom: 8px
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow {
    height: 8px;
    margin-left: -8px;
    width: 16px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow,
.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow {
    height: 16px;
    margin-left: 0;
    margin-top: -8px;
    width: 8px
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-background {
    display: none
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-arrow-border {
    border: 8px solid transparent
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-border {
    border-bottom-color: $brand-primary;
    border-bottom-color: rgba($brand-primary,0.9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-left .tooltipster-arrow-border {
    border-left-color: $brand-primary;
    border-left-color: rgba($brand-primary,0.9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-border {
    border-right-color: $brand-primary;
    border-right-color: rgba($brand-primary,0.9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-arrow-border {
    border-top-color: $brand-primary;
    border-top-color: rgba($brand-primary,0.9)
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-bottom .tooltipster-arrow-uncropped {
    top: -8px
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-right .tooltipster-arrow-uncropped {
    left: -8px
}