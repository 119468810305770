#chrono {
  position:relative;
  .chrono-value,.chrono-minutes,.chrono-value-small {
    position:absolute;
    left:50%;
    font-family:Lobster;
    text-align:center;
    color:$brand-primary;
    width:164px;
  }
  .chrono-minutes {
    font-size: 2em;
    bottom: 36px;
    margin-left:-82px;
  }
  .chrono-value-small {
    margin-left:-82px;
    top:90px;
    font-size:1.6em;
  }
  .chrono-value {
    margin-left:-88px;
    top:62px;
    font-size:4em;
  }
}

