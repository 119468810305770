.swaltheme {
  width: 70% !important;
  min-height: 350px !important;
  border: 5px solid $brand-primary;

  .list-number-style {
    list-style-type: decimal;
  }
  
}

