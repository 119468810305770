.dragndrop {
    &.choice {
        position: relative;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        margin-top: 25vh;
        
        .source_choice {
            margin-top: 20px;

            .dragndrop-item {
                display: inline-block;
                vertical-align: middle;
                margin: 2px;
                padding: 5px 5px 5px 30px;
                line-height: 28px;
                background-color: $brand-primary;
                color: $brand-white;
                position: relative;
                margin: 10px;
                border: 0;
                cursor: move;
                text-align: center;

                &:before {
                    transition: opacity .5s;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    transform: translateY(-50%);
                    color: white;
                    content: '\f047';
                    font-family: FontAwesome;
                }

                &.dragndrop-item-invisible {
                    display: none;
                }
            }
        }

        .validate {
            float: right; 
            opacity: 0;
            visibility: hidden;
            transition: all 1s;

            &.transition-visible {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .dragndrop_slider {
        .reponse {
            margin-left:16px;
            display:inline-block;
            opacity:0;
            transition:opacity 1s;
        }
        .bad {
            + .reponse {
                opacity:1;
            }
        }
    }

}