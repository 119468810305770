.crossword-container {
    margin: 0 15px;
    height: 300px;
}

#puzzle-wrapper {
    float: left;
    width: 50%;
    table {
        border-collapse: collapse;
        border-spacing: 0;
        max-width: 100%;
    }
    table tr {
        width: 100%;
    }
    table td {
        width: 1.8em;
        height: 1.8em;
        border: 2px solid transparent;
        padding: 0;
        margin: 0;
        background-color: transparent; /* Couleur de fond des cases vides */
        position: relative;
    }

    td input {
        width: 100%;
        height: 100%;
        padding: 0em;
        border: none;
        text-align: center;
        font-size: 1.5em;
        color: #666;
        background-color: #f4f4f4;
        text-transform: uppercase;
    }

    td input:focus {
        background-color: #fff;
        color: #999;
    }

    td span {
        color: #444;
        font-size: 0.8em;
        position: absolute;
        top: -1px;
        left: 1px;
    }

    input.done {
        font-weight: bold;
        color: white;
        background-color: green;

    }

    .active,
    .clues-active {
        background-color: #999;
    }
    .clue-done {
        color: #999;
        text-decoration: line-through;
    }
}

#puzzle-wrapper {
    float: left;
    width: 50%;
    height: 300px;
}

#puzzle-clues {
    padding-left: 16px;
    float: left;
    width: 50%;
    color: white;
    li.active,
    li.clues-active {
        background-color: #999;
    }
    li.clue-done {
        color: white;
        text-decoration: line-through;
    }
}

