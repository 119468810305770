font-face {
  font-family: 'Lobster 1.3';
  src: ('Lobster13.eot');
  src: ('Lobster13.eot?#iefix') format('embedded-opentype'),
  ('Lobster13.woff2') format('woff2'),
  ('Lobster13.woff') format('woff'),
  ('Lobster13.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

///// HEADERS FONT FAMILY ---------//
h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-base;
}

///// HEADERS FONT //////////////
h1, .section-title {
  font-size: $font-twofive;
  color: $brand-primary;
}

h3 {
  font-size: $font-small;
}

///// FONT SIZES ////////////////////////
.font-xxsmall {
  font-size: $font-xxsmall;
}

.font-xsmall {
  font-size: $font-xsmall;
}

.font-small {
  font-size: $font-small;
}

.font-base {
  font-size: $font-base;
}

.font-base-1 {
  font-size: $font-base-1;
}

.font-base-2 {
  font-size: $font-base-2;
}

.font-base-3 {
  font-size: $font-base-3;
}

.font-medium-1 {
  font-size: $font-medium-1;
}

.font-medium-2 {
  font-size: $font-medium-2;
}

.font-medium-3 {
  font-size: $font-medium-3;
}

.font-large {
  font-size: $font-large;
}

.font-xlarge {
  font-size: $font-xlarge;
}

.font-xxlarge {
  font-size: $font-xxlarge;
}

.font-double {
  font-size: $font-double;
}

.font-triple {
  font-size: $font-triple;
}

.font-huge {
  font-size: $font-huge;
}

///// SPACERS ///////////////
.spacer-5, .spacer-10, .spacer-20, .spacer-30, .spacer-40, .spacer-50, .spacer-75, .spacer-100 {
  background-color: lightgrey
}

;

.spacer-10 {
  height: 10px;
}

.spacer-20 {
  height: 20px;
}

.spacer-30 {
  height: 30px;
}

.spacer-40 {
  height: 40px;
}

.spacer-50 {
  height: 50px;
}

.spacer-75 {
  height: 75px;
}

.spacer-100 {
  height: 100px;
}

.logo-mooc {
  margin: auto;
  display: table;
  img {
    width: 60%;
    margin: 30px auto 30px;
    display: block;
  }
}

.login-hr{
  width: 100%;
  height: 5px;
  background-color: $primary;
}

///// USERS/LOGING.CTP ----------------------------------//
// -!- learniz_xlarge/large/medium/small/xsmall.gif !
.learniz-gif-log {
  margin-top: 30px;
  margin-bottom: 15px;
}

.logo-client-log { //logo-client-log.jpg
  animation-delay: 1s;
  height: 50px;
}

.titre-mooc-log {
  font-size: 2.5em;
  font-family: 'Raleway';
  font-weight: bold;
  margin-top: 15px;
}

.help-log {
  font-size: 1.5em;
  font-family: $font-title;
  text-transform: uppercase;
  color: $brand-brown;
}

.login-img-bg {
  position: absolute;
  left: 0;
  img {
    position: fixed;
    width: 100%;
  }
}

.form-login {
  .email {
    &:before {
      content: '';
      background-image: url(../img/ico_log.png);
      background-repeat: no-repeat;
      width: 27px;
      height: 28px;
      position: absolute;
      left: 22.5%;
      top: 33%;
    }
  }
  .password {
    &:before {
      content: '';
      background-image: url(../img/ico_pw.png);
      background-repeat: no-repeat;
      width: 27px;
      height: 28px;
      position: absolute;
      left: 22.5%;
      top: 53.5%;
    }
  }
  .input-text {
    display: block;
    font-size: $font-base-2;
    padding: 8px 40px;
    margin: 5px auto;
    border: solid 2px $brand-brown;
    border-radius: 20px;
    &.input-email{width: 60%;}
    &.input-password{width: 60%;}
    &:-webkit-input-placeholder, &:-moz-placeholder, &:-ms-placeholder {
      color: $brand-brown;
    }
    &:focus {
      border-color: $brand-dark-red;
      color: $brand-dark-red;
      outline: 0;
    }
  }

  .button-log {
    margin: 20px 50% 0;
    transform: translateX(-50%);
    font-size: 1em;
    font-family: $font-smtitle;
    text-transform: uppercase;
    color: #fff;
    border-radius: 0;
    border: none;
    box-shadow: none;
    border-radius: 20px;
    background-color: $brand-primary;	
    &:hover {
      background-color: darken($brand-primary, 5%);
    }

  }

}

.form-log {
  padding-top: 20px;
  padding-bottom: 20px;

  background: transparent;

}

/// CONTENT ///////////////////////////////

.block {
  min-height: 64px;
  line-height: 64px;
  display: block;
  padding: 0 16px;
  border-bottom: solid 1px $brand-primary;
  &:first-child() {
    border-top: solid 1px $brand-primary;
  }
  &.synthese {
    background-color: $brand-primary;
    color: white;
    font-weight: bold;
  }
}

/// LOG BUTTON ////////////////////////////

a.forgotten-pass {
  float: right;
  color: $brand-primary;
  font-family: $font-smtitle;
  margin-right: 20%;
}

.success-message {
  color: white;
  background-color: $brand-success;
}

.error-message {
  font-size: $font-base;
  font-weight: bold;
  color: white;
  width: 100%;
  margin-bottom: 8px;
  padding: 5px;
  text-align: center;
  background-color: $brand-primary;
}

///// USERS/FORGOT.CTP ----------------------------------//

.forgot-pad {
  margin-top: 20%;

  .form-log {
    margin-top: 1%;
    .input-forgot{
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

.forgot-title {
  font-size: $font-medium-1;
}

.forgot-subtitle {
  font-size: $font-medium-1;
  line-height: 1.1em;
}

.forgot-help {
  font-size: $font-base-1;
  line-height: 1.2em;
  font-style: italic;
}

#video {
  position: relative;
  &.paused {
    .vidoverlay {
      &:before {
        padding-left: 7px;

        content: "\f04b";
      }
    }
  }
  .progression {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 0;
    height: 5px;

    transition: all 0.5s linear;

    background-color: $brand-primary;
  }
  .vidoverlay {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    transition: opacity 0.3s;

    opacity: 0;
    &:before {
      font-family: FontAwesome;
      font-size: 3em;
      line-height: 128px;

      position: absolute;
      top: 50%;
      left: 50%;

      width: 128px;
      height: 128px;
      margin-top: -64px;
      margin-left: -64px;

      content: "\f04c";

      color: white;
      border-radius: 128px;
      background-color: $brand-primary;
      box-shadow: 0 0 13px 2px rgba(0, 0, 0, 0.5);
    }
    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
}

$offest-drawer: 48px;

.offset {
  padding-left: $offest-drawer;
  transition: all 0.3s;
  // padding-top:10px;
}
