

.nytmm_qz
{
	font-family:Arial,Helvetica,sans-serif;
	font-size:12px;
	line-height:15px;
	margin-top:10px;
	position:relative;
}

.nytmm_qz_content
{
	position:relative;
}

.nytmm_qz_buttons
{
	text-align: right;
}

.nytmm_qz_clear,.btn-primary:focus, .btn-primary.focus {

background-color: white;
}

.nytmm_qz_clear:hover, {
background-color: $brand-primary;
color: white;
border: solid $brand-primary 1px; 
}



.nytmm_button
{
	-moz-border-radius:2px;
	background: blue;
	border:none;
	border-radius:2px;
	color: white;
	cursor:pointer;
	float:left;
	font-family:Arial,Helvetica,sans-serif;
	font-size:14px;
	line-height:13px;
	margin-right:10px;
	padding:5px 10px;
	position:relative;
	text-align:center;
	text-transform:uppercase;
	width:160px;
}
 

.nytmm_qz_next,.nytmm_qz_submit
{
	display:none;
}

.nytmm_qz_next
{
	left:0;
	position:absolute;
	top:0;
}

.nytmm_qz_question
{
	position:relative;
}

.nytmm_qz_container
{
	position:relative;
}

.nytmm_qz_question h4
{
	font-size:15px;
	font-weight:400;
	line-height:18px;
	margin-bottom:15px;
	background-color: #EDEDED;
}

.nytmm_credit
{
	color:#999;
	font-size:9px;
	margin:1px 0 0;
	padding:0;
	text-align:right;
}

.nytmm_caption
{
	font-size:10px;
	line-height:13px;
	margin-bottom:10px;
}

.nytmm_qz_response
{
	color:#666;
	display:none;
	padding-top:10px;
}

.nytmm_qz_response span
{
	font-weight:700;
}

.nytmm_qz_response img
{
	float:right;
	margin:0 0 10px 10px;
}

.nytmm_qz_choices
{
	position:relative;
	width: 100%;
	z-index:0;
}

.nytmm_qz_mc
{
	border:1px solid #fff;
	clear:both;
	cursor:pointer;
	margin:10px 0 2px 10px;
	padding:3px 0 3px 3px;
	position:relative;
}

.nytmm_qz_mc_choice
{
	float:left;
}

.nytmm_qz_mc p
{
	font-size:12px;
	line-height:14px;
	margin-top:3px;
}

.nytmm_qz_checkbox
{
	background-image:url(http://graphics8.nytimes.com/packages/flash/us/20110914_GRUNGE/checkbox_colors_checkmark.gif);
	background-position:0 0;
	background-repeat:no-repeat;
	float:left;
	height:24px;
	margin-right:8px;
	width:24px;
}

.nytmm_qz_mc.nytmm_selected p
{
	font-style:italic;
}

.nytmm_qz_mc.nytmm_selected .nytmm_qz_checkbox
{
	background-position:-24px 0;
}

.nytmm_qz_choices.nytmm_answered .nytmm_qz_mc
{
	cursor:auto;
}

.nytmm_qz_choices.nytmm_answered .nytmm_qz_mc.nytmm_selected .nytmm_qz_checkbox
{
	background-position:-72px 0;
}

.nytmm_qz_choices.nytmm_answered .nytmm_qz_mc.nytmm_correct .nytmm_qz_checkbox
{
	background-position:-48px 0!important;
}

.pointA,.pointB
{
	/*height:45px;*/
	position:relative;
	font-family: 'Arial';
	font-size: 0.95em; 
	text-align: center;
	color:white;
	border-radius: 0px;
	padding:5px;
	min-height:48px;

}

.pointA
{
	width:47%;
	border: solid  $brand-primary 2px;
    background-color: white;
	color: black; 
	float:left;
}


.pointB
{
	width:35%;
	background-color: $brand-success;
	float:right;
	text-transform: uppercase;
	animation: flash infinite; 

	&.impulsif {
		background-color: white;
		border: solid #E51A2D 2px;
		color:  #E51A2D;

	}
	&.joueur {
		background-color: white;
		border: solid #FFC000 2px;
		color:  #FFC000;
	}
	&.distant {
		background-color: white;
		border: solid #70AD47 2px;
		color:  #70AD47;
	}
	&.connaisseur {
		background-color: white;
		border: solid #4472C4 2px;
		color:  #4472C4;
	}
	&.exigeant {
		background-color: white;
		border: solid #7030A0 2px;
		color:  #7030A0;
	}
	&.consensuel {
		background-color: white;
		border: solid #ED7D31 2px;
		color:  #ED7D31;
	}
}

.divider
{
	clear:both;
	padding:8px 0;
}


.pointA span,.pointB span
{
	display:block;
}

.pointB span.nytmm_correct
{
	/*color:#2A872A;*/
	color:white; /*ok*/
}

.pointB span.nytmm_wrong
{
	/*color:#990B08;*/
	color:white;
	text-decoration:line-through;
}

.nytmm_indiv_clear
{
	cursor:pointer;
	display:none;
	font-size: 12px;
	text-transform:uppercase;
	text-align: center;
	background-color: black;
	color: white;
	border: solid 1px pink;
	border-radius: 2px;
	padding: 5px;
	border: solid purple 1px;

}

.nytmm_paper_mask
{
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
	background:#fff;
	filter:alpha(opacity=0);
	height:490px;
	left:0;
	opacity:0;
	position:absolute;
	top:0;
	width:580px;
}

.nytmm_paper
{
	cursor:pointer;
	width:400px;
	height:490px;
	left:0;
	position:absolute;
	top:0;
	z-index:1000;
}

.nytmm_qz_results
{
	background-color:#F0F4F5;
	display:none;
	margin:20px 0;
	padding:10px;
	position:relative;
}

.nytmm_qz_results h3
{
	font-size:16px;
	margin-bottom:6px;
}

.nytmm_qz_score,.nytmm_qz_feedback
{
	margin:10px;
	color: red;
}

.nytmm_qz_score
{
	font-size:16px;
}

.nytmm_qz_feedback_image
{
	margin:15px 10px;
}

.nytmm_shareTools
{
	height:24px;
	margin-bottom:28px;
	position:absolute;
	right:10px;
	top:10px;
	width:60px;
}

