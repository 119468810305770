.layer {
  //display:none;
  opacity:0;
  visibility:hidden;
  transition:all 0.75s;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3000;
  &.semi {
    background-color: rgba(0,0,0,0.5);
  }
  &.transition-visible {
    opacity:1;
    visibility:visible;
  }
}

/** Basculler les classes du chapitre 10 à 12 à vid-inter */
.vid-inter {
  display:block;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  input[type=checkbox] {
    display:none;
    + label {
      display:block;
      position:absolute;
      border:solid 2px rgba($brand-primary,0.5);
      &:hover {
        border-color:rgba($brand-primary,0.85);
      }
    }
    &:checked {
      + label {
        border:solid 3px rgba($brand-success,0.75);
        &:hover {
          border-color:rgba($brand-success,1);
        }
      }
    }
  }

  /***

  CHAPITRE 10

  ***/
  input[name='in_5_10_0_1'] {
    + label {
      left:13%;
      right:62%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_0_2'] {
    + label {
      left:13%;
      right:54%;
      top:50%;
      bottom:39.5%;
    }
  }
  input[name='in_5_10_0_3'] {
    + label {
      left:13%;
      right:51%;
      top:63.8%;
      bottom:25.7%;
    }
  }
  input[name='in_5_10_0_4'] {
    + label {
      left:55.5%;
      right:13%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_0_5'] {
    + label {
      left:65%;
      right:13%;
      top:50%;
      bottom:39.5%;
    }
  }
  input[name='in_5_10_0_6'] {
    + label {
      left:50%;
      right:13%;
      top:63.8%;
      bottom:25.7%;
    }
  }

  input[name='in_5_10_1_1'] {
    + label {
      left:13%;
      right:70%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_1_2'] {
    + label {
      left:13%;
      right:52%;
      top:50%;
      bottom:39.5%;
    }
  }
  input[name='in_5_10_1_3'] {
    + label {
      left:13%;
      right:66%;
      top:63.8%;
      bottom:25.7%;
    }
  }


  input[name='in_5_10_2_1'] {
    + label {
      left:13%;
      right:43%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_2_2'] {
    + label {
      left:13%;
      right:32%;
      top:50%;
      bottom:39.5%;
    }
  }

  input[name='in_5_10_3_1'] {
    + label {
      left:13%;
      right:50%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_3_2'] {
    + label {
      left:13%;
      right:50%;
      top:50%;
      bottom:39.5%;
    }
  }
  input[name='in_5_10_3_3'] {
    + label {
      left:55.5%;
      right:13%;
      top:36.4%;
      bottom:53.2%;
    }
  }
  input[name='in_5_10_3_4'] {
    + label {
      left:55.5%;
      right:13%;
      top:50%;
      bottom:39.5%;
    }
  }



  button {
    position:absolute;
    bottom:10%;
    top:79%;
    left:37.5%;
    right:10%;
    width: 25%;
    color:white;
    display:inline-block;
    border-radius:128px;
    text-transform:uppercase;
    font-size:1.5em;
    border:none;
    background-color: $brand-primary;
  }
  /**** FIN CHAPITRE 10 ****/

  /***

  CHAPITRE 11

  ***/
  a {
    display:block;
    position:absolute;
    border:solid 2px rgba($brand-primary,0.5);
    top: 61%;
    bottom: 8%;
    left:37%;
    right:37%;
    z-index:5000;
    &[data-played] {
      border-color:rgba($brand-success,0.8);
      &:hover {
        border-color:rgba($brand-success,0.8);
      }
    }
    &:hover {
      border-color:rgba($brand-primary,0.8);
      background-color: rgba(255,255,255,0.1);
    }

    &#ch11-v02,
    &#ch11-v03,
    &#ch11-v04,
    &#ch11-v05 {
      top:47%;
      bottom:33%;
    }
    &#ch11-v02 {
      left: 12%;
      right: 74%;
    }
    &#ch11-v03 {
      left: 35%;
      right: 55.5%;
    }
    &#ch11-v04 {
      left: 54%;
      right: 33%;
    }
    &#ch11-v05 {
      left: 76%;
      right: 13%;
    }
  }

  input[name='in_5_11_0_1'] {
    + label {
      left:12%;
      right:66%;
      top:35.4%;
      bottom:52.2%;
    }
  }
  input[name='in_5_11_0_2'] {
    + label {
      left:12%;
      right:21%;
      top:49%;
      bottom:38.5%;
    }
  }
  input[name='in_5_11_0_3'] {
    + label {
      left:12%;
      right:69%;
      top:62.8%;
      bottom:24.7%;
    }
  }

  input[name='in_5_11_1_1'] {
    + label {
      left:12%;
      right:33%;
      top:37.4%;
      bottom:50.9%;
    }
  }
  input[name='in_5_11_1_2'] {
    + label {
      left:12%;
      right:13%;
      top:49.5%;
      bottom:36%;
    }
  }
  input[name='in_5_11_1_3'] {
    + label {
      left:12%;
      right:46%;
      top:64.8%;
      bottom:22.7%;
    }
  }
  /*** FIN CHAPITRE 11 ***/

  /*** CHAPITRE 12 ***/
  input[name='in_6_12_0_1'] {
    + label {
      left:15%;
      right:52%;
      top:38%;
      bottom:50%;
    }
  }
  input[name='in_6_12_0_2'] {
    + label {
      left:15%;
      right:42%;
      top:52.5%;
      bottom:36%;
    }
  }
  input[name='in_6_12_0_3'] {
    + label {
      left: 15%;
      right: 39%;
      top: 66%;
      bottom: 21.7%;
    }
  }

  input[name='in_6_12_1_1'] {
    + label {
      left: 15%;
      right: 65%;
      top: 32%;
      bottom: 56%;
    }
  }
  input[name='in_6_12_1_2'] {
    + label {
      left: 15%;
      right: 69%;
      top: 45.5%;
      bottom: 42%;
    }
  }
  input[name='in_6_12_1_3'] {
    + label {
      left: 15%;
      right: 59%;
      top: 59.5%;
      bottom: 28.7%;
    }
  }

  .lnk_6_12_0 {
    top: 6%;
    bottom: 82%;
    left: 55%;
    right: 16%;
  }
  /*** FIN CHAPITRE 12 ***/

  /*** CHAPITRE 13 ***/
  input[name='in_6_13_0_1'] {
    + label {
      left:12.5%;
      right:64%;
      top:36%;
      bottom:52.5%;
    }
  }
  input[name='in_6_13_0_2'] {
    + label {
      left:12.5%;
      right:64%;
      top:49.5%;
      bottom:39%;
    }
  }
  input[name='in_6_13_0_3'] {
    + label {
      left: 12.5%;
      right: 68%;
      top: 63%;
      bottom: 25%;
    }
  }

  input[name='in_6_13_1_1'] {
    + label {
      left: 12.5%;
      right: 20.5%;
      top: 36%;
      bottom: 52.5%;
    }
  }
  input[name='in_6_13_1_2'] {
    + label {
      left: 12.5%;
      right: 37%;
      top: 49.5%;
      bottom: 39%;
    }
  }
  input[name='in_6_13_1_3'] {
    + label {
      left: 12.5%;
      right: 34.5%;
      top: 63.5%;
      bottom: 25%;
    }
  }
  input[name='in_6_13_2_1'] {
    + label {
      left: 12.5%;
      right: 52.5%;
      top: 36%;
      bottom: 52.5%;
    }
  }
  input[name='in_6_13_2_2'] {
    + label {
      left: 12.5%;
      right: 23.5%;
      top: 49.5%;
      bottom: 39%;
    }
  }
  input[name='in_6_13_2_3'] {
    + label {
      left: 12.5%;
      right: 22.5%;
      top: 63.5%;
      bottom: 25%;
    }
  }

  .in_6_13_3 {
    top:67%;
    bottom:3%;
    left:40%;
    right:40%;
  }
  /*** FIN CHAPITRE 13 ***/

  /*** CHAPITRE 14 */
  .lnk_6_14_0 {
    top:32%;
    bottom:27%;
    left:67%;
    right:7.5%;
  }
  .lnk_6_14_1 {
    top:56%;
    bottom:6%;
    left:82.5%;
    right:3%;
  }

  input[name='in_6_14_0_1'],input[name='in_6_14_1_1'],input[name='in_6_14_2_1'] {
    + label {
      left:24%;
      right:60%;
      top:74%;
      bottom:13.5%;
    }
  }
  input[name='in_6_14_0_2'],input[name='in_6_14_1_2'],input[name='in_6_14_2_2'] {
    + label {
      left:46.5%;
      right:37%;
      top:74%;
      bottom:13.5%;
    }
  }
  input[name='in_6_14_0_3'],input[name='in_6_14_1_3'],input[name='in_6_14_2_3'] {
    + label {
      left: 69.5%;
      right: 14%;
      top:74%;
      bottom: 13.5%;
    }
  }

  /** FIN DU CHAPITRE 14 */

  /** CHAPITRE 15 */
  .lnk_7_15_0 {
    top:68%;
    bottom:3%;
  }
  /** FIN CHAPITRE 15 */
}


/** redéfinition  du style pour la vidéo du chapitre 14 */
#vid-6-14-1-0 {
  .layer {
    &.semi {
      background-color:transparent;
    }
  }
  .interract {
    transition:all 1s;
    transform:scale(1);
    box-shadow:none;
    background-color: transparent;
    top:auto;
    bottom:5px;
    left:0;
    width:100%;
    margin:0;
    border-radius:0;
    height:auto;
  }
  button {
    top:87%;
    bottom:3%;
  }
}

  [data-type='loop'] {
    .playpause {
      display:none;
    }
  }
  [data-type="link"] {
    .playpause {
      display:none;
      background-color: $brand-success;
      text-transform:uppercase;
      border-radius:50px;
      color:white;
      position:absolute;
      top:50%;
      left:50%;
      margin-left:-120px;
      margin-top:-24px;
      height:48px;
      line-height:48px;
      width:240px;
      cursor:pointer;
    }
  }


.paused {
  [data-type="link"] {
    .vid-inter {
      background-color: rgba(0,0,0,0.5);
    }
    .playpause {
      display:block;
    }
  }
}