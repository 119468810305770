.missingwords {

    margin-left: 50px;

    .missingwords-text {
        margin: 20px;
        padding: 20px;
        background-color: $brand-grey;
        border-left: 5px solid $brand-primary;
        cursor: default;
        line-height: 1.5;
    }

    .missingwords-propositions {
        min-height: 100px;
        

        .missingwords-proposition {
            border: 1px solid $brand-black;
            padding: 15px;
            margin: 5px 10px;

            display: inline-block;
            min-width: 100px;
            text-align: center;
            cursor: move;

            &.word-dropped {
                display: none;
            }
        }
    }

    .dragme {
        text-align: center;
        &.word {
            display: inline-block;
            vertical-align: middle;
            margin: 2px;
            padding: 5px 5px 5px 30px;
            line-height: 28px;
            background-color: $brand-primary;
            color: $brand-white;
            position: relative;
            margin: 10px;
            border: 0;
            &:before {
                transition: opacity .5s;
                position: absolute;
                top: 50%;
                left: 10px;
                
                transform: translateY(-50%);
                color: white;
                content: '\f047';
                font-family: FontAwesome;
            }

            &.ui-draggable-dragging {
                background-color: transparentize($color: $brand-primary, $amount: 0.6);
            }

            &.word-dropped-invisible {
                display: none;
            }
        }
        
        &.landing {
            min-width: 80px;
            height: 24px;
            line-height: 24px;
            padding: 2px 5px;
            margin: 0 5px;
            background-color: $brand-white;
            display: inline-block;
            &:empty {
                &:after {
                    content: '\f019';
                    font-size: 1em;
                    color: $brand-grey-dark;
                    font-family: FontAwesome;
                }
            }

            &.word-dropped-ok {
                background-color: $brand-dark-green;
                color: $brand-white;
            }

            &.word-dropped-ko {
                background-color: $brand-dark-red;
                color: $brand-white;
            }

            &.ui-state-hover {
                background-color: transparentize($color: $brand-primary, $amount: 0.5);
                transform:scale(1.05);
            }
        }
    }

}