.image-progressive {
  position: relative;

  .image-in-cycle {
    max-height: 50vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
    margin-left: 60px;

    &.image-visible {
      opacity: 1;
      transition:visibility 0.3s linear,opacity 0.3s linear;
    }
  }

  .btn-next {
    margin-top: 55vh;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: $brand-dark-green;
    box-shadow: 5px 5px 0 $brand-alt-green;
    color: $brand-white;
    border-radius: 0;
  }

}