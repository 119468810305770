/** Colors **/
$darkGreen: #4a6a28;
$lightGreen: #87ba51;
$greyState: #DDD;
$mainBlue: #0070c0;

ol {
  
    &.step-track {
        display: table;
        list-style-type: none;
        margin: 0;
        padding: 1.5em 1em 3em;
        table-layout: fixed;
        width: 100%;    

        li {
            display: table-cell;
            line-height: 3em;
            position: relative;
            text-align: center;

            .icon-wrap {
                border-radius: 50%;
                top: -1.75em;
                color: #fff;
                display: block;
                width: 3em;
                height: 3em;
                line-height:3em;
                margin: 0 auto 0em;
                left: 0;
                right: 0;        
                position: relative;
                .fa-check {
                    display:inline;
                }
                .fa-reply {
                    display:none;
                    &:before {
                        transform:scaleY(-1);
                    }
                }
                i {
                    vertical-align:middle;
                }
            }

            .step-text {
                position: relative;
                top: -1.5em;
                font-weight:300;
                color:black;
            }

            &.step {
                a, a:hover {
                    text-decoration:none;
                }
                &.done {
                    border-top: 5px solid $gray;
                    transition: border-color 1s ease-in-out;
                    -webkit-transition: border-color 1s ease-in-out;
                    -moz-transition: border-color 1s ease-in-out;

                    .icon-down-arrow {
                        display: none;
                    }        

                    &.current {

                        .icon-wrap {
                            background-color: $brand-primary;
                            .fa-check {
                                display:none;
                            }
                            .fa-reply {
                                display:inline;
                            }
                        }        
                    }                

                    .icon-wrap {
                        background-color: $brand-primary;
                    }                
                }

                &.todo {
                    border-top: 5px solid $gray;
                    color: black;
                    /* a {
                        pointer-events: none;
                        cursor:default;
                    }*/
                    &.active {
                        .icon-wrap {
                            background-color: lighten($brand-primary, 40);
                            i.fa {
                                color:lighten($brand-primary, 40);
                            }
                        }
                    }
                    .icon-wrap {
                        background-color: $gray;
                        border-radius: 50%;
                        top: -1.75em;
                        color: #fff;
                        display: block;
                        width: 3em;
                        height: 3em;
                        margin: 0 auto 0em;
                        i.fa {
                            color:$gray;
                        } 
                    }        
                }            
            }
        }    
    }
}