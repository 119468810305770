
.table-lettrine {
  margin:auto;
}
.content-lettrine {
  border:solid 1px white;
  border-radius:16px;
  margin-bottom:16px;
  .texte {
    margin-left: 120px;
    line-height:77px;
    font-size:38px;
    color:white;
  }
  .lead {margin-bottom:10px}

  &.success {
    .lettrine {
      background-color: $brand-success;
    }
    border-color: $brand-success;
  }
  .lettrine {
    background-color: white;
    width:96px;
    height:96px;
    border-radius:16px;
    float:left;
    line-height:96px;
    text-align:center;
    font-size:5em;
    color:#006045;
    font-family:sans-serif;
    box-shadow: 2px 2px 2px black;
  }


  
  .grand{
    font-size: 35px;
    font-weight: bold;
  }

.clear {
  clear:both;
}

}


